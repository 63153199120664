import { ITokenData } from './../interfaces/tokenData';
import environment from '@/environment';
import { IUser } from './../interfaces/user';
import { AuthStateInterface } from './../interfaces/authState';
import { Module, MutationTree } from 'vuex';
import { RootStateInterface } from '@/store/rootState';

const startUser: IUser = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  roles: [''],
  companyName: '',
  locale: 'pl',
  loginDate: new Date().getTime(),
};

export const state: AuthStateInterface = {
  user: null,
  accessToken: null,
  refreshToken: null,
  tokenExpiresIn: null,
  avatarToken: null,
  lastPage: 'unknown',
};

export const mutations: MutationTree<AuthStateInterface> = {
  updateUser(oldState: AuthStateInterface, payload: IUser) {
    oldState.user = payload;
  },
  updateAvatarToken(oldState: AuthStateInterface, payload: ITokenData) {
    oldState.avatarToken = Object.assign({}, oldState.avatarToken, payload);
  },
  updateTokenData(oldState: AuthStateInterface, payload: ITokenData) {
    oldState.accessToken = payload.accessToken;
    oldState.refreshToken = payload.refreshToken;
    oldState.tokenExpiresIn = payload.tokenExpiresIn;
  },
  initUser(oldState: AuthStateInterface) {
    oldState.user = Object.assign({}, startUser);
  },
  logout(oldState: AuthStateInterface) {
    oldState.user = Object.assign({}, startUser);
    oldState.accessToken = null;
    oldState.refreshToken = null;
    oldState.tokenExpiresIn = null;
    localStorage.removeItem(environment.authLocalStorageKey);
  },
};

const namespaced: boolean = true;

export const auth: Module<AuthStateInterface, RootStateInterface> = {
  namespaced,
  state,
  mutations,
};
