const environment = {
  production: (process.env.VUE_APP_MODE === 'production'),
  authLocalStorageKey: 'endora.mortgage.authData',
  clientId: process.env.VUE_APP_API_CLIENT_ID,
  clientSecret: process.env.VUE_APP_API_CLIENT_SECRET,
  backendUrl: process.env.VUE_APP_API_BACKEND_URL,
  apiPrefix: process.env.VUE_APP_API_PREFIX,
  websocketUrl: process.env.VUE_APP_WS_BACKEND_URL,
  supportEmail: process.env.VUE_APP_SUPPORT_EMAIL,
  maxUploadFileSize: process.env.VUE_APP_MAX_FILE_SIZE_UPLOAD,
};

export default environment;
