import enLocale from 'element-ui/lib/locale/lang/en';

export default {
  test: 'Test EN',
  search: 'Search for client...',
  backend: {
    general: {
      userNotExist: 'User with provided credentials doesn\'t exist',
      wrongUserPass: 'Username or password incorrect',
    },
  },
  general: {
    save: 'Save',
    accountNotConfirmed: `Your account is not confirmed - click on the link sent to your e-mail address to confirm it.
      If not, then 30 days after registration account will be suspended`,
    goToSettings: 'Go to settings',
    sendMailAgain: 'Send mail again',
    mailSent: 'Mail sent - check your inbox',
    confirmYourAccount: 'Confirm your account',
    personalDataNotFilled: `Please fill your personal data connected with account -
      it is important for creating correct invoice`,
    fillPersonalData: 'Fill personal data',
    clickToCopy: 'Click to copy the value',
    delete: 'Delete',
    edit: 'Edit',
    back: 'Back',
    refreshData: 'Refresh data',
    authorizationError: 'You are not authorized to perform this action!',
    massActions: 'Actions',
    warning: 'Warning!',
    unassign: 'Unassign',
    cancel: 'Cancel',
    newClient: 'Create New Client',
    newAdministrator: 'Create New Administrator',
    of: 'of',
    error: 'An error occured - pleasy try again later or contact administrators',
    nonUniqueNameError: 'Given name is already used.',
    notificationDropdownError: 'An error occurred when collecting notifications, please try again',
  },
  menu: {
    dashboard: 'Dashboard',
    profile: 'User profile',
    settings: 'Settings',
    logout: 'Logout',
    impersonate: 'Impersonate',
    workers: 'Workers',
    projects: 'Projects',
    workspaces: 'Workspaces',
    reports: 'Reports',
    tags: 'Tags',
    timesheet: 'Timesheet',
    invitations: 'Invitations',
    clients: 'Clients',
    messages: 'Messages',
    credits: 'Credits',
    brokers: 'Brokers',
    allClients: 'All Clients',
    applicationsInProgress: 'Applications In Progress',
    completedMortgages: 'Completed Mortgages',
    mortgages: 'Mortgages',
    allMortgages: 'All mortgages',
    company: 'Company',
  },
  forms: {
    required: 'This field is required',
    earlierDate: 'Please choose earlier date',
    number: 'Please provide valid number',
    date: 'Must be a type of date',
    showButton: 'Show',
    assignToMe: 'Delegate Permissions',
    unnasignFromMe: 'Un-Delegate Permissions',
    detailsButton: 'Details',
    editButton: 'Edit',
    options: {
      langs: {
        AF: 'Afghanistan',
        AX: 'Åland Islands',
        AL: 'Albania',
        DZ: 'Algeria',
        AS: 'American Samoa',
        AD: 'Andorra',
        AO: 'Angola',
        AI: 'Anguilla',
        AQ: 'Antarctica',
        AG: 'Antigua and Barbuda',
        AR: 'Argentina',
        AM: 'Armenia',
        AW: 'Aruba',
        AU: 'Australia',
        AT: 'Austria',
        AZ: 'Azerbaijan',
        BS: 'Bahamas',
        BH: 'Bahrain',
        BD: 'Bangladesh',
        BB: 'Barbados',
        BY: 'Belarus',
        BE: 'Belgium',
        BZ: 'Belize',
        BJ: 'Benin',
        BM: 'Bermuda',
        BT: 'Bhutan',
        BO: 'Bolivia',
        BA: 'Bosnia and Herzegovina',
        BW: 'Botswana',
        BV: 'Bouvet Island',
        BR: 'Brazil',
        IO: 'British Indian Ocean Territory',
        BN: 'Brunei Darussalam',
        BG: 'Bulgaria',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        KH: 'Cambodia',
        CM: 'Cameroon',
        CA: 'Canada',
        CV: 'Cape Verde',
        KY: 'Cayman Islands',
        CF: 'Central African Republic',
        TD: 'Chad',
        CL: 'Chile',
        CN: 'China',
        CX: 'Christmas Island',
        CC: 'Cocos (Keeling) Islands',
        CO: 'Colombia',
        KM: 'Comoros',
        CG: 'Congo',
        CD: 'Congo, The Democratic Republic of the',
        CK: 'Cook Islands',
        CR: 'Costa Rica',
        CI: 'Cote D\'Ivoire',
        HR: 'Croatia',
        CU: 'Cuba',
        CY: 'Cyprus',
        CZ: 'Czech Republic',
        DK: 'Denmark',
        DJ: 'Djibouti',
        DM: 'Dominica',
        DO: 'Dominican Republic',
        EC: 'Ecuador',
        EG: 'Egypt',
        SV: 'El Salvador',
        GQ: 'Equatorial Guinea',
        ER: 'Eritrea',
        EE: 'Estonia',
        ET: 'Ethiopia',
        FK: 'Falkland Islands (Malvinas)',
        FO: 'Faroe Islands',
        FJ: 'Fiji',
        FI: 'Finland',
        FR: 'France',
        GF: 'French Guiana',
        PF: 'French Polynesia',
        TF: 'French Southern Territories',
        GA: 'Gabon',
        GM: 'Gambia',
        GE: 'Georgia',
        DE: 'Germany',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GR: 'Greece',
        GL: 'Greenland',
        GD: 'Grenada',
        GP: 'Guadeloupe',
        GU: 'Guam',
        GT: 'Guatemala',
        GG: 'Guernsey',
        GN: 'Guinea',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        HM: 'Heard Island and Mcdonald Islands',
        VA: 'Holy See (Vatican City State)',
        HN: 'Honduras',
        HK: 'Hong Kong',
        HU: 'Hungary',
        IS: 'Iceland',
        IN: 'India',
        ID: 'Indonesia',
        IR: 'Iran, Islamic Republic Of',
        IQ: 'Iraq',
        IE: 'Ireland',
        IM: 'Isle of Man',
        IL: 'Israel',
        IT: 'Italy',
        JM: 'Jamaica',
        JP: 'Japan',
        JE: 'Jersey',
        JO: 'Jordan',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        KI: 'Kiribati',
        KP: 'Democratic People\'s Republic of Korea',
        KR: 'Korea, Republic of',
        XK: 'Kosovo',
        KW: 'Kuwait',
        KG: 'Kyrgyzstan',
        LA: 'Lao People\'s Democratic Republic',
        LV: 'Latvia',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libyan Arab Jamahiriya',
        LI: 'Liechtenstein',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        MO: 'Macao',
        MK: 'Macedonia, The Former Yugoslav Republic of',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshall Islands',
        MQ: 'Martinique',
        MR: 'Mauritania',
        MU: 'Mauritius',
        YT: 'Mayotte',
        MX: 'Mexico',
        FM: 'Micronesia, Federated States of',
        MD: 'Moldova, Republic of',
        MC: 'Monaco',
        MN: 'Mongolia',
        ME: 'Montenegro',
        MS: 'Montserrat',
        MA: 'Morocco',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NL: 'Netherlands',
        AN: 'Netherlands Antilles',
        NC: 'New Caledonia',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NU: 'Niue',
        NF: 'Norfolk Island',
        MP: 'Northern Mariana Islands',
        NO: 'Norway',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PS: 'Palestinian Territory, Occupied',
        PA: 'Panama',
        PG: 'Papua New Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippines',
        PN: 'Pitcairn',
        PL: 'Poland',
        PT: 'Portugal',
        PR: 'Puerto Rico',
        QA: 'Qatar',
        RE: 'Reunion',
        RO: 'Romania',
        RU: 'Russian Federation',
        RW: 'Rwanda',
        SH: 'Saint Helena',
        KN: 'Saint Kitts and Nevis',
        LC: 'Saint Lucia',
        PM: 'Saint Pierre and Miquelon',
        VC: 'Saint Vincent and the Grenadines',
        WS: 'Samoa',
        SM: 'San Marino',
        ST: 'Sao Tome and Principe',
        SA: 'Saudi Arabia',
        SN: 'Senegal',
        RS: 'Serbia',
        SC: 'Seychelles',
        SL: 'Sierra Leone',
        SG: 'Singapore',
        SK: 'Slovakia',
        SI: 'Slovenia',
        SB: 'Solomon Islands',
        SO: 'Somalia',
        ZA: 'South Africa',
        GS: 'South Georgia and the South Sandwich Islands',
        ES: 'Spain',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SJ: 'Svalbard and Jan Mayen',
        SZ: 'Swaziland',
        SE: 'Sweden',
        CH: 'Switzerland',
        SY: 'Syrian Arab Republic',
        TW: 'Taiwan',
        TJ: 'Tajikistan',
        TZ: 'Tanzania, United Republic of',
        TH: 'Thailand',
        TL: 'Timor-Leste',
        TG: 'Togo',
        TK: 'Tokelau',
        TO: 'Tonga',
        TT: 'Trinidad and Tobago',
        TN: 'Tunisia',
        TR: 'Turkey',
        TM: 'Turkmenistan',
        TC: 'Turks and Caicos Islands',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'United Arab Emirates',
        GB: 'United Kingdom',
        US: 'United States',
        UM: 'United States Minor Outlying Islands',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela',
        VN: 'Viet Nam',
        VG: 'Virgin Islands, British',
        VI: 'Virgin Islands, U.S.',
        WF: 'Wallis and Futuna',
        EH: 'Western Sahara',
        YE: 'Yemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe',
      },
      currencies: {
        PLN: 'Polish zloty (PLN)',
        EUR: 'Euro (EUR)',
        USD: 'US Dollars (USD)',
        GBP: 'Great Britain pounds (GBP)',
      },
      currenciesSuffix: {
        PLN: 'zł',
        EUR: '€',
        USD: '$',
        GBP: '£',
      },
    },
    error: 'Error: {msg}',
    invalidEmail: 'Provide e-mail with valid format',
    optional: '(optional)',
    passwordsNotMatch: 'Passwords do not match',
    invalidPhoneNumber: 'Provide phone number with valid format e.g. +443333222222',
  },
  dashboard: {
    recentClients: 'Recent Clients',
    mortgageStatus: {
      resolved: 'Completed',
      ongoing: 'Ongoing',
    },
    mortgageRenewals: 'Mortgage Renewals',
  },
  impersonate: {
    general: 'Impersonate',
    email: 'E-mail',
    impersonate: 'Impersonate',
    success: 'Success. You are login as: ',
  },
  login: {
    title: 'Login to Timemize',
    email: 'E-mail address',
    password: 'Password',
    login: 'Login',
    forgotPassword: 'Forgot password',
    dontHaveAccount: 'Don\'t have an account?',
  },
  logout: {
    success: 'User logged out successfully',
  },
  languagePicker: {
    pl: 'Polski',
    en: 'English',
  },
  profileDropdown: {
    myProfile: 'My profile',
    switchBroker: 'Switch Broker',
    logout: 'Logout',
    licenseDaysLeft: '{daysLeft} days left to license end',
  },
  workspaceDropdown: {
    successChange: 'Workspace was changed!',
    errorChange: 'Workspace wasnt changed!',
  },
  typePicker: {
    line: 'Line chart',
    pie: 'Pie chart',
    bar: 'Bar chart',
    table: 'Data table',
  },
  dataTable: {
    delete: 'Delete',
  },
  datePicker: {
    startDate: 'Start date',
    endDate: 'End date',
    lastWeek: 'Last 7 days',
    lastTwoWeeks: 'Last 14 days',
    lastSmallMonth: 'Last 28 days',
    lastMonth: 'Last 30 days',
    lastThreeMonths: 'Last 3 months',
    lastSixMonths: 'Last 6 months',
    lastYear: 'Last year',
  },
  chart: {
    noData: 'No data for chart',
    refresh: 'Refresh chart',
    navigateUp: 'Zoom out',
  },
  profile: {
    title: 'User profile',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    name: 'Company Name',
    vatnr: 'NIP',
    phone: 'Phone',
    street: 'Street',
    streetNumber: 'Street Number',
    houseNumber: 'House Number',
    companyName: 'Company',
    postcode: 'Postcode',
    city: 'City',
    country: 'Country',
    success: 'Profile data updated successfully',
    error: {
      onlyLetters: 'Only letters!',
    },
  },
  notification: {
    header: 'Send Notification',
    title: 'Title',
    body: 'Message',
    send: 'Send',
    sendSuccess: 'Notification sent',
  },
  settings: {
    title: 'Settings',
    changePasswordTitle: 'Change Password',
    changePassword: 'Change Password',
    oldPassword: 'Current Password',
    password: 'New Password',
    repeatPassword: 'Repeat New Password',
    changePasswordSuccess: 'Password changed successfully',
    currentPasswordIncorrect: 'Your current password is incorrect',
    general: 'General',
    currency: 'Base currency',
    changeSuccess: 'Data changed successfully',
  },
  resetPassword: {
    title: 'Please provide your e-mail address to send a reset password link',
    backToLogin: 'Back to login',
    success:
      'We sent you an e-mail with password reset link - check your inbox',
    label: 'Send',
    email: 'E-mail address',
    failure: 'Error during password reset process, please try again',
    failureMsg: 'Error during password reset process: {msg}',
  },
  setNewPassword: {
    title: 'Provide new password',
    backToLogin: 'Back to login',
    success: 'Password changed successfully',
    password: 'New password',
    repeatPassword: 'Repeat new password',
    label: 'Change password',
    failure: 'Error during password reset process, please try again',
    failureMsg: 'Error during password reset process: {msg}',
  },
  addEntry: {
    cardTitle: 'Add entry',
    title: 'Title',
    addBtn: 'Add entry',
    project: 'Project',
    mode: 'Mode',
    timeFrom: 'Time from',
    timeTo: 'Time to',
    duration: 'Duration',
    cost: 'Cost',
    description: 'Description',
    selectDateTime: 'Select date and time',
  },
  projects: {
    title: 'Projects',
    error: {
      unassignErrorUserIsOwnerOfProject: 'You cannot unassign from {name} project because you are it\'s owner',
    },
    unassignSuccessFromProjects: 'You have been successfully signed out of project | You have been successfully signed out of {count} projects',
    unassignFromProjectsWarning: 'Do you want to unassign from selected projects? This action cannot be reverted!',
    unassignFromProjectWarning: 'Do you want to unassign from this project? This action cannot be reverted!',
  },
  workers: {
    title: 'Workers',
    addWorker: 'Add worker',
    email: 'E-mail',
    firstName: 'First Name',
    lastName: 'Last Name',
    addWorkerPage: {
      title: 'Add worker',
      createAccount: 'Create new user',
      email: 'E-mail',
      emailText: 'Input user email',
      submitButton: 'Add worker',
      workerAdded: 'The worker has been added!',
      workerRemoved: 'The worker has been removed!',
      role: 'Role',
      options: {
        role: {
          ADMIN_WORKSPACE: 'Administrator',
          OWNER_WORKSPACE: 'Owner',
          WORKER_WORKSPACE: 'Worker',
        },
      },
    },
    showWorkerPage: {
      title: 'Worker details',
      returnButton: 'Return to workers list',
    },
    removeWorkerPopup: {
      title: 'Warning! You want remove worker!',
      info: 'Are you sure to remove  ',
      confirmButton: 'Yes',
      cancelButton: 'No',
    },
  },
  workspaces: {
    title: 'Workspaces',
    add: 'Add workspace',
    addForm: {
      title: 'New workspace',
      name: 'Name',
      domain: 'Domain',
      add: 'Add',
      success: 'Successfully added workspace.',
      generalError: 'Adding workspace failed. Try again.',
    },
    error: {
      unassignErrorUserIsOwnerOfWorkspace: 'You cannot unassign from {name} workspace because you are it\'s owner',
      unassignErrorUserIsOwnerOfProject: 'You cannot unassign from {name} workspace because it has project that you are owner of',
      unassignErrorUserHasProjectInWorkspace: 'You cannot unassign from {name} workspace because you are member of project in this workspace. Unassign first from all projcts in {name} workspace',
    },
    unassignSuccessFromWorkspaces: 'You have been successfully signed out of workspace | You have been successfully signed out of {count} workspaces',
    unassignFromWorkspacesWarning: 'Do you want to unassign from selected workspaces? This action cannot be reverted!',
    unassignFromWorkspaceWarning: 'Do you want to unassign from this workspace? This action cannot be reverted!',
  },
  invitations: {
    sentInvitations: 'Sent invitations',
    recivedInvitations: 'Received invitations',
    returnButton: 'Return to invitations list',
    table: {
      userName: 'Send to',
      adminName: 'Send from',
      resourceName: 'Name',
      resourceType: 'Type',
      resourceTypeList: {
        workspace: 'Workspace',
        project: 'Project',
      },
      role: 'Role',
      roleList : {
        owner_project: 'Owner project',
        admin_project: 'Admin project',
        worker_project: 'Worker project',
        owner_workspace: 'Owner workspace',
        admin_workspace: 'Admin workspace',
        worker_workspace: 'Worker workspace',
      },
      resourceList: {
        workspace: 'Workspace',
        project: 'Project',
      },
      statusList: {
        active: 'Active',
        accepted: 'Accepted',
        rejected: 'Rejected',
        expired: 'Expiried',
      },
      receivedTime: 'Recived date',
      expireTime: 'Expire date',
      status: 'Status',
      acceptButton: 'Join',
      rejectButton: 'Reject',
      deleteButton: 'Delete',
      invitationAccepted: 'Invitation was accepted',
      invitationDeleted: 'Invitation was deleted',
      invitationRejected: 'Invitation was rejected',
      popup: {
        acceptInvitationTitle: 'Accept invitation',
        acceptInvitationInfo: 'Do You want accept invitation from  ',
        deleteInvitationTitle: 'Delete invitation',
        deleteInvitationInfo: 'Do You want delete invitation for ',
        rejectInvitationTitle: 'Reject invitation',
        rejectInvitationInfo: 'Do You want reject invitation from ',
        confirmButton: 'Yes',
        cancelButton: 'No',
        invitationErrorRejected: 'Invitation was rejected',
        invitationErrorExpired: 'Invitation was expired',
        invitationErrorAccepted: 'Invitation was accepted',
      },
    },
  },
  popup: {
    functionNoReady: {
      title: 'Information',
      info: 'This function is not available in current version.',
    },
  },
  mortgage: {
    status: {
      applicationSubmitted: 'Application submitted',
      valuationAndSurvey: 'Valuation & Survey',
      offerSubmitted: 'Offer issued', // old  'Offer submitted'
      exchangeContracts: 'Exchange Contracts',
      completion: 'Completion',
    },
    error: {
      createErrorValueTooBig: 'This value has to be more than 0 and less than {value}',
      errorInStateName: 'Only letters in state name!',
      errorInPostCode: 'Please use Capital Letters and numbers only',
      errorInAddress: 'Only numbers, letters and - , . ( ) / chars!',
    },
  },
  client: {
    createMortgageButton: 'Create a New Mortgage',
  },
  company: {
    title: 'Company',
    form: {
      name: 'Name',
      logo: 'Logo',
      save: 'Save',
    },
  },
  ...enLocale,
};
