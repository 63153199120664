/**
 * Place every JS code that bootstraps dependencies
 * (for example configure Vue plugins etc.)
 */

import './i18n';
import './vue-router';
import './vue-clipboard';
import './element-ui';
import './vue-wyswig';
import './vue-number-animation';
